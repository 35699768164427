:root {
  --site-width: 1200px;
  --box-border: 1px solid rgba(0, 0, 0, 0.2);
  --box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.2);
  --border-radius: 4px;
  --primary: #0d6efd;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: sans-serif;
}

body {
  background-color: #333;
  color: rgba(0, 0, 0, 0.95);
}

#contentBackground {
  background-color: #f6f6f6;
}

#content {
  max-width: var(--site-width);
  min-width: 900px;
  margin: 0 auto;
  padding: 1px 6px 32px 6px;
}

.react-flow__node.selected {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) !important;
  border-width: 2px !important;
  margin-top: -1px !important;
  margin-left: -1px !important;
  border-radius: 8px !important;
}

.react-flow__handle {
  width: 6px !important;
  height: 6px !important;
  background-color: white !important;
  border: 1px solid black !important;
}

.react-flow__handle:hover {
  background-color: black !important;
}

.react-flow__handle-right {
  right: -4px !important;
}

.react-flow__handle-left {
  left: -4px !important;
}

#messageTray {
  max-width: var(--site-width);
  margin: 0 auto;
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  z-index: 100;
}

#messageTray .message {
  background-color: rgb(221, 69, 69);
  color: #eee;
  padding: 12px;
  border-radius: var(--border-radius);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  margin-top: 12px;
}

#messageTray .message p {
  flex: 1;
  margin: 0;
  padding: 12px 0;
}

#messageTray .message button {
  margin: 0;
  background: transparent;
  border: 1px solid #eee;
  color: #eee;
  float: right;
  padding: 0 24px;
  line-height: 6px;
}

#messageTray .message button:hover {
  background: rgba(255, 255, 255, 0.05);
}

#messageTray .message button:active {
  background: rgba(255, 255, 255, 0.1);
}

.nodata {
  text-align: center;
  padding: 24px 0;
  color: #888;
  margin: 0;
}
