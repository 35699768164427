.PipelineBlock {
  height: 800px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: var(--border-radius) !important;
  margin-bottom: 12px !important;
}

.PlantNode,
.ProductNode {
  border-color: rgba(0, 0, 0, 0.8) !important;
  color: black !important;
  font-size: 13px !important;
  border-width: 1px !important;
  border-radius: 6px !important;
  box-shadow: 0px 2px 4px -3px black !important;
  width: 100px !important;
}

.PlantNode {
  background-color: #8d8 !important;
}

.ProductNode {
  background-color: #e6e6e6 !important;
}
