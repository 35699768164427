.FormRow {
  display: flex;
  line-height: 24px;
}

.FormRow label {
  width: 350px;
  padding: 6px 12px;
  text-align: right;
}

.FormRow input {
  flex: 1;
  font-family: monospace;
  border: var(--box-border);
  border-radius: var(--border-radius);
  padding: 4px;
  margin: 2px 3px;
}

.FormRow_unit {
  color: rgba(0, 0, 0, 0.4);
}

.invalid {
  border: 2px solid #faa !important;
  background-color: rgba(255, 0, 0, 0.05);
}
